html {
	font-size: 16px;
	font-family: "Open Sans", serif;
	color: #424242;
}

html,
body {
	width: 100%;
	position: relative;
	margin: 0;
	padding: 0;
}
header {
	#logo {
		height: 95%;
		background-repeat: no-repeat;
		flex-basis: 11rem;
		width: 178px;
		padding-left: 4%;
		margin-left: 0%;
	}
}

body {
	min-height: 100vh;
}

#logo {
	background-image: url("../img/logo.png");
	background-repeat: no-repeat;
	background-size: contain;
	height: 65px;
}

.portada-slide {
	border-radius: 5px;
	padding: 1%;
}

.creem {
	background-image: url("../img/fonsportada.png");
	background-size: contain;
	height: 500px;
	width: 100%;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
	color: #fff;
	text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: "Raleway", sans-serif;

	a {
		color: inherit;
	}
}

ul,
p {
	margin: 0;
	padding: 0;
}

.wow {
	visibility: hidden;
}

a {
	color: $primary;
	text-decoration: none;
}

header {
	a:hover {
		text-decoration: none;
	}
}

main {
	overflow: hidden;
	clear: both;
	min-height: 18rem;
	position: relative;
	padding-bottom: 2.5rem;

	ul {
		margin: 0 0 0 2.5rem;
		padding: 0;
	}
}

.jumbotron {
	background: #fff;
	padding: 30px 0;
	border-radius: 0;
	border: none;
	margin: 0;

	h1 {
		color: #494949;
		text-align: center;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 900;
		text-transform: uppercase;
	}
}

footer {
	background: $primary;
	color: white;
	padding: 3rem 0;

	p {
		margin: 0;
		padding: 0;

		a {
			color: inherit;
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}

		a i {
			color: inherit;
		}
	}
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 0.5rem 1rem;
}

.fb-icons {
	width: 26px;
}
.idiomes {
	list-style: none;

	li {
		float: left;
		display: inline;
		margin: 0 3px;
	}

	li a {
		color: #333;
	}

	li a.selected {
		color: #333;
		font-weight: bold;
	}
}

#croqueteros {
	h1 {
		display: none;
	}

	span {
		color: #cf1116;
	}
}
#historia {
	font-family: "Raleway";
	.vermellet {
		float: left;
		margin: 0;
		color: #d41116;
		font-weight: 900;
		font-size: 56px;
		padding-right: 5%;
	}
	.blanquet {
		font-weight: 500;
		color: #fff;
		text-transform: uppercase;
		margin: 0%;
		padding-top: 9%;
		font-size: 1rem;
		line-height: 25px;
	}
	.griset {
		color: #494949;
		font-weight: 900;
	}
}

#Croquetes {
	.jumbutron {
		padding: 30px 0 7px;
	}

	#z_custom {
		margin-right: 17%;
		margin-left: 17%;
	}

	h3 {
		text-align: center;
		color: #494949;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom: 3%;
	}

	h1 {
		text-align: center;
		color: #d41116;
		font-size: 1.7rem;
		font-family: "Caveat", cursive;
		text-transform: none;
	}

	p {
		color: #3f3f3f;
		font-family: "Raleway", sans-serif;
		font-weight: 400;
		text-align: center;
	}

	.griset {
		color: #494949;
		font-weight: 900;
	}

	.vermellet {
		font-weight: 900;
		color: #d41116;
	}

	.buit {
		background: #fff;
		color: #d41116;
		border-color: #d41116;
	}

	.buit::before {
		background-color: #fff;
		content: "";
		position: absolute;
		z-index: 2;
		width: 150%;
		height: 100%;
		left: 100%;
		top: 0;
		transition: 1s cubic-bezier(0.65, 0.05, 0.36, 1);
		transform: translateX(-17px) skewX(-45deg);
		transform-origin: bottom;
	}
}

#Productes {
	.jumbutron {
		padding: 30px 0 7px;
	}

	#z_custom {
		margin-right: 17%;
		margin-left: 17%;
	}

	h3 {
		text-align: center;
		color: #494949;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom: 3%;
	}

	h1 {
		text-align: center;
		color: #d41116;
		font-size: 1.7rem;
		font-family: "Caveat", cursive;
		text-transform: none;
	}

	p {
		color: #3f3f3f;
		font-family: "Raleway", sans-serif;
		font-weight: 400;
		text-align: center;
	}

	.griset {
		color: #494949;
		font-weight: 900;
	}

	.vermellet {
		font-weight: 900;
		color: #d41116;
	}

	.buit {
		background: #fff;
		color: #d41116;
		border-color: #d41116;
	}

	.buit::before {
		background-color: #fff;
		content: "";
		position: absolute;
		z-index: 2;
		width: 150%;
		height: 100%;
		left: 100%;
		top: 0;
		transition: 1s cubic-bezier(0.65, 0.05, 0.36, 1);
		transform: translateX(-17px) skewX(-45deg);
		transform-origin: bottom;
	}
}

#historia {
	background-color: #b7a89d;
	background-image: url("../img/barra-historia.png");
	background-size: contain;
}

#menu a {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
}

.portada-slide {
	.tit-crem {
		font-family: "Caveat", cursive;
		padding-top: 24%;
		font-size: 3rem;
	}

	.titol-slide {
		font-family: Raleway;
		font-size: 3rem;
	}
}

#textperque {
	background-color: #f6e7e8;
	border-radius: 2rem;

	h4,
	h5 {
		margin-bottom: 0px;
	}

	h5 {
		font-weight: 600;
	}
}

#perquefinal {
	h5 {
		margin-bottom: 2.5rem;
	}
}

#perqueinici {
	h4 {
		margin-top: 2.5rem;
	}
}

#icones {
	margin-bottom: 4rem;
	flex-direction: column;

	img {
		max-width: 85%;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	h6 {
		font-weight: 600;
		font-size: 1rem;
	}
}

#icones1 {
	h6 {
		font-size: 1.1rem;
	}
}
