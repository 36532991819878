// Carreguem les variables de bootstrap per poder-les utilitzar aquí
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


// Podem sobreescriure qualsevol de les variables que hi ha a: "~bootstrap/scss/_variables.scss"
$primary: #D41116;


// Podem modificar un theme-color o crear-ne un de nou també:
// https://getbootstrap.com/docs/5.0/customize/sass/#modify-map
$custom-colors: (
		"primary": $primary,
		"login": #c2c2c2,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
