/*==========  Mobile First Method  ==========*/
html {
  font-size: 14px;
}

#slide {
  h2 {
	font-size: 1rem;
  }

  p {
	font-size: .8rem;
  }
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 769px) {

  header {
	position: sticky;
	top: 0;
	z-index: 100;
	background: #fff;
	transition: 0.3s min-height;

	&.compressed {
	  box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);

	  #logo h1 {
		font-size: 2rem;
	  }

	  #info-header .hidden-on-scroll {
		margin-top: -4rem;
	  }
	}

	#logo {
	  flex-basis: 15rem;

	  h1 {
		font-size: 4rem;
		transition: 0.3s all;
	  }
	}

	#info-header {
	  flex-basis: 15rem;

	  .hidden-on-scroll {
		transition: 0.3s all;
	  }
	}

  }

  #slide {
	h2 {
	  font-size: 3rem;
	}

	p {
	  font-size: 2rem;
	}
  }

  #icones {
	display: none;
  }

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  html {
	font-size: 15px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
  html {
	font-size: 16px;
  }
}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .nivo-caption .tbl .tbl-cell {
	vertical-align: middle;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

  body {
	padding-top: 4rem;
  }

  .container {
	min-width: 100%;
  }

  .jumbotron {
	padding: 0;
	margin: 0;
  }
  .jumbotron h1 {
	font-size: 2rem;
	padding: 1rem;
  }

  footer .column {
	width: 100% !important;
  }

	#perque {
	img {
		width: 0%;
	}
	}

  #textperque {
	width: 100%;
  }

  #icones1 {
	display: none;
  }

}


/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}

@media (min-width: 1200px) {
  .container {
	max-width: 1200px;
  }
}
