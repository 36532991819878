#menu {
  font-family: 'Caveat', Sans-serif!important;
  font-size:1.2rem;
}

@media only screen and (max-width: 768px) {
  #box_menu {
	z-index: 10000;
  }
  #menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100000;
	margin: 0;
	overflow: auto;
	max-height: 100%;
	background: $primary;
	box-shadow: 0 3px 5px rgba(57, 63, 72, 0.2);
  }
  #menu .into_box {
	margin: 0;
	padding: 0;
  }
  #menu .sf-menu ul {
	box-shadow: none;
	display: block;
	width: 100%;
  }
  #menu li {
	background: none !important;
	color: #fff;
  }
  #menu .sf-menu a {
	border:none;
	margin: 0;
	padding: 0.8rem 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	color: #fff;
	font-weight: 600;
	font-size: 1rem;
  }
  #menu .sf-menu a:hover {
	background: none !important;
  }
  #menu .sf-menu li {
	white-space: normal;
  }
  #menu .sf-menu li li {
	background: rgba(0, 0, 0, 0.15);
  }
  #menu .sf-menu li li a {
	padding-left: 2.5rem;
  }
  #menu .sf-menu li li li a {
	padding-left: 3.75rem;
  }

  #menu h1 {
	font-size: 2rem;
	color: #fff;
  }

  #mobnav-btn {
	padding: 1.1rem;
  }
  #mobnav-btn i {
	font-size: 24px;
	color: #fff;
  }
  #mobnav-btn .fa-stack {
	color: #fff;
  }
  .mobnav-subarrow {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	z-index: 10000000;
	font-size: 1.5rem;
	padding: .47rem 1.1rem;
	background: rgba(0, 0, 0, 0.15);
  }
  .mobnav-subarrow i {
	color: #fff;
  }
  .sf-menu {
	width: 100% !important;
	display: none;
	margin: 0;
  }
  .sf-menu > li {
	background: none
  }
  .sf-menu a {
	border-left: none;
  }
  .sf-menu.xactive {
	display: block !important;
  }
  .sf-menu li {
	float: none !important;
	display: block !important;
	width: 100% !important;
  }
  .sf-menu li a {
	float: none !important;
  }
  .sf-menu ul {
	position: static !important;
	display: none !important;
  }
  .xpopdrop > ul {
	display: block !important;
  }


  /* Amaguem les fletxes per defecte */
  .sf-arrows .sf-with-ul:after {
	content: none;
  }
}

@media only screen and (min-width: 769px) {

  #menu .sf-menu {
	margin: 0;
	float: none;
  }

  #mobnav-btn, .titol_nav, .mobnav-subarrow {
	display: none;
  }

  .sf-menu li {
	white-space: normal;
	*white-space: normal; /* ...unless you support IE7 (let it wrap) */
	-webkit-transition: background .2s;
	transition: background .2s;
	background: transparent;
  }
  .sf-menu ul {
	box-shadow: none;
  }
  .sf-menu ul li {
	background: transparent !important;
  }
  /*.sf-menu li:hover, .sf-menu li.sfHover{background: #383838 !important;color:#fff;}*/
  .sf-menu li.sfHover a {
	color: $primary !important;
  }
  .sf-menu li li a {
	margin: 0;
	border-top: none;
  }
  #menu ul li.last a {
	border: none;
  }
  #menu a {

	padding: 1.7rem 1.5rem;
	font-size: 1rem;
	font-weight: 600;
	white-space: nowrap;
	color: black;
	margin: 0;
	border-left: none;
	border-top: none;
  }
  #menu a:hover {
	text-decoration: none;
  }
  #menu a.sf-with-ul {
	padding-right: 30px;
  }

  #menu li > a.has-dropdown {
	padding: 3px 20px !important;
  }
  #menu a.selected {
	color: $primary !important;
  }

  /** arrows general **/
  #menu .sf-arrows > li > a.selected.sf-with-ul:after {
	border-top-color: black !important;
  }
  .sf-arrows > li > .sf-with-ul:focus:after, .sf-arrows > li:hover > .sf-with-ul:after, .sf-arrows > .sfHover > .sf-with-ul:after {
	border-top-color: $primary;
  }
  .sf-arrows .sf-with-ul:after {
	border-top-color: #888;
	right: 0.7em;
  }
  /** arrows submenu **/
  .sf-arrows ul .sf-with-ul:after {
	border-left-color: #888;
  }
  .sf-arrows ul li > .sf-with-ul:focus:after, .sf-arrows ul li:hover > .sf-with-ul:after, .sf-arrows ul > .sfHover > .sf-with-ul:after {
	border-left-color: $primary;
	border-top-color: #fff;
  }

  #menu .sf-menu > li > a:hover {
	color: $primary !important;
  }

  #menu li li a {
	padding: 6px 20px;
	border-right: none;
	/*background: rgba(255,255,255,0.9);*/
	background: #fff;
	color: black !important;
	font-size: 12px;
	font-weight: 500;
	white-space: normal;
  }
  #menu li li a:hover {
	color: $primary !important;
  }

  #menu li.has-dropdown a {
	padding-right: 26px;
  }

  .sf-menu li:hover,
  .sf-menu li.sfHover {
	background: transparent;
	/* only transition out, not in */
	-webkit-transition: none;
	transition: none;
  }

}
