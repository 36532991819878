/** Slide **/
.bx-wrapper {
  box-shadow: none;
  padding: 0;
  border: none;
  margin-bottom: 0;
  background-color: transparent;

  .bx-default-pager {
    position: absolute;
    bottom: 0.7rem;
    left: 0;
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .bx-pager-item {
    text-align: center;
  }

  .bx-pager.bx-default-pager a {
    background: #fff;
  }

  .bx-pager.bx-default-pager a:hover {
    background: $primary;
  }

  .bx-pager.bx-default-pager a.active {
    background: $primary;
  }

  .bx-controls-direction a {
    z-index: 99;
    text-indent: 0;
    width: auto;
    margin-top: -2rem;
  }

  .bx-controls-direction a:hover i {
    color: $primary !important;
  }

  .bx-controls-direction a i {
    font-size: 2.2rem;
    padding: 0.5rem;
  }

  img {
    margin-bottom: 0 !important;
  }

  * {
    color: #fff;
  }
}

.bx-wrapper .bx-controls-auto, .bx-wrapper .bx-pager {
  bottom: 1rem;
}


/** effectes hover **/
.effect_hover {
  .hover {
    display: block;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .hidden {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10;
    transition: 0.3s all;
    overflow: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .topBottom {
    height: 100%;
    top: -100%;
    left: 0;
  }

  .leftRight {
    height: 100%;
    left: -100%;
    width: 100%;
  }

  .rightLeft {
    height: 100%;
    right: -100%;
    width: 100%;
  }

  .bottomTop {
    height: 100%;
    bottom: -100%;
    left: 0;
  }

  .hidden .text {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 100% !important;
    color: #fff;
    padding: 1.25rem;
  }

  .hidden .text h4 {
    color: #fff !important;
  }

  .hover:hover .hidden {
    opacity: 1;
  }

  .hover:hover .topBottom {
    top: 0;
  }

  .hover:hover .leftRight {
    left: 0;
  }

  .hover:hover .rightLeft {
    right: 0;
  }

  .hover:hover .bottomTop {
    bottom: 0;
  }

  .invertit .hidden {
    opacity: 1;
  }

  .invertit .topBottom {
    top: 0;
  }

  .invertit .leftRight {
    left: 0;
  }

  .invertit .rightLeft {
    right: 0;
  }

  .invertit .bottomTop {
    bottom: 0;
  }

  .invertit:hover .hidden {
    opacity: 1;
  }

  .invertit:hover .topBottom {
    top: 100%;
  }

  .invertit:hover .leftRight {
    left: 100%;
  }

  .invertit:hover .rightLeft {
    right: 100%;
  }

  .invertit:hover .bottomTop {
    bottom: 100%;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.7rem;
  }

  p {
    font-size: 1rem;
    font-weight: normal;
  }
}

/** Destacats portada */
.destacats {
  .icona {
    font-size: 6rem;
    text-align: center;
    padding: 2rem 0 0 0;
    color: $primary;
  }
}

/** Mostrar la data triangle en notícies portada **/
.data-triangle {
  color: #222;
  position: absolute;
  top: 0;
  left: 0;
  width: 9rem;
  height: 9rem;
  padding: 0.5rem;
  background: linear-gradient(135deg, $primary 50%, transparent 50%);

  span {
    display: block;
    font-weight: bold;
    color: #fff;
  }

  span.dia {
    font-size: 2.1rem;
    line-height: 2.3rem;
  }

  span.mes {
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: 1.3rem;
    font-weight: 500;
  }

  span.any {
    font-size: 1rem;
    font-weight: normal;
  }
}

/** paginador **/
.page-item.active .page-link {
  background: $primary;
  border: 1px solid $primary;
}

.page-item.active {
  color: #fff !important;
}

.page-link {
  color: #424242;
}

.page-link:hover {
  color: $primary;
}

/** fletxa en moviment **/
#fletxa_moviment {
  position: absolute;
  width: 4rem;
  height: 4rem;
  left: 50%;
  margin-left: -2rem;
  text-align: center;
  z-index: 10;
  font-size: 2rem;
  color: #fff;
  display: none;
  animation: MoveUpDown 3s linear infinite;
  bottom: 8rem;

  svg {
    width: 90%;
  }
}

@keyframes MoveUpDown {
  0% {
    bottom: 3.75rem;
  }
  50% {
    bottom: 2.5rem;
  }
  100% {
    bottom: 3.75rem;
  }
}

/** fletxa tornar al principi **/
.back-to-top {
  position: fixed;
  overflow: hidden;
  bottom: 2em;
  right: 2em;
  text-decoration: none;
  color: #fff;
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  display: none;
  z-index: 1000;

  i {
    display: block;
    line-height: 3rem;
    margin: 0 auto;
    font-size: 2.5rem;
    color: #000;
  }
}


/** owl carousel **/
.owl-carousel {
  button {
    background: none !important;
  }

  button.owl-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    left: -40px;
    z-index: 10000;
    font-size: 40px;
  }

  button.owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    right: -40px;
    z-index: 10000;
    font-size: 40px;
  }

  button {
    padding: 0;
    margin: 0;
  }

  .owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
  }

  button i {
    font-size: 3.1rem;
    line-height: 3.1rem !important;
    margin: 0;
    padding: 0;
  }

  button svg {
    width: 4rem;
    padding: .4rem;
  }

  button.disabled i, button.disabled svg {
    color: lighten($primary, 30%);
  }

  .owl-stage-outer {
    display: flex;
  }

  .owl-stage {
    display: flex !important;
  }

  .owl-dots {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
  }

  .owl-dot {
    display: inline-block;
    margin: 0.2rem;
  }

  .owl-dot span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    background: grey;
    border-radius: 100%;
  }

  .owl-dot.active span {
    background: $primary;
  }
}


/** cercador **/
#cercar {
  padding: 0 1.8rem 0 0.2rem;
  background: #EEEEEE;
  position: relative;
  border-radius: 1.8rem;
  width: 9rem;

  input {
    background: #EEEEEE;
    border-radius: 30px;
    width: 100%;
  }

  i {
    position: absolute;
    right: 0.8rem;
  }

  input[type="search"] {
    height: 1.8rem;
    padding-right: 0;
    line-height: 1.8rem;
    border: none !important;
    font-size: 0.9rem
  }

  input[type="search"]:focus,
  input[type="search"]:active,
  input[type="search"]:hover,
  input[type="search"]:active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
}


/* Data en format "cube" */
.data-box {
  position: relative;
  overflow: hidden;
  clear: both;
  text-align: center;
  background: #EEEEEE;
  padding: 10px 0;
  height: auto !important;

  span {
    position: relative;
    clear: bottom;
    text-align: center;
    display: block;
    color: #000;
  }

  span.dia {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.3rem;
  }

  span.mes {
    text-transform: uppercase;
    line-height: 1.3rem;
    font-size: 1.2rem;
  }

  span.any {
    font-size: 1rem;
  }
}


/* Per la lliure "parallax" */
.parallax-window {
  min-height: 25rem;
  background: transparent;
}

.parallax-text {
  padding: 2.5rem;
}

#nose2_parallax .grid-item {
  margin-bottom: 0 !important;
}

/* Estil bàsic per la bona visualització de les lliures */
.lliures {

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
  }

  .bx-wrapper {
    margin-bottom: 1rem;
  }

  table {
    border: none;
  }

  ul {
    margin-top: 1.25rem;
  }

  .icon {
    width: 100%;
    position: relative;
  }

  .icon i {
    font-size: 10rem;
  }

  .grid-item {
    margin-bottom: 2.5rem;
  }

  img {
    margin-bottom: 1.25rem;
  }

  .fluid-width-video-wrapper {
    margin-bottom: 1.25rem;
  }
}


.spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 101;
  background: #fff;

  .spinner-grow {
    margin: 0 .2rem;
  }
}

.box_spinner {
  display: flex;
  height: calc(100vh - 12.5rem);
  align-items: center;
  justify-content: center;
}

.box_spinner .spinner-border {
  color: $primary;
  width: 4rem;
  height: 4rem;
}

.box_spinner .spinner-grow {
  color: $primary;
}

